/* notice popups */

.notice-container {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.notice {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 100%;
  border-radius: 6px;
  padding: 40px 20px;
  background-color: #ffffff;
  align-items: center;
  box-shadow: 0px 0px 10px -1px #9a9a9a;
  -webkit-box-shadow: 0px 0px 10px -1px #9a9a9a;
  -moz-box-shadow: 0px 0px 10px -1px #9a9a9a;
}

.notice-close {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  margin: 8px 8px 0 0;
  justify-content: center;
  height: 25px;
  width: 25px;
  padding: 2px;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  -moz-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  cursor: pointer;
}

.notice-text {
  text-align: center;
  user-select: none;
}

.notice-ok {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  max-width: 100%;
  padding: 2px;
  margin-top: 20px;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  -moz-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  cursor: pointer;
}
/* end notice popups */

div.notice-container div.notice div.notice-text p {
  font-weight: 700;
}

div.notice-container div.notice div.notice-text span {
  color: #99CC33;
}
