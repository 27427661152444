.switcherContainer {
  position: absolute;
  display: flex;
  z-index: 5;
  top: 4px;
  max-width: 40%;
  width: 90px;
  right: 85px;
}

.switch {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 28px;
  cursor: pointer;
  background-color: #e9e9e9;
  border-radius: 6px;
}

.metricSwitchTitle {
  display: flex;
  flex-direction: column;
  cursor: default;
  user-select: none;
  text-align: center;
}

.switchText {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  place-content: center;
  user-select: none;
  font-size: 12px;
  color: #666;
  transition: color .4s;
  -webkit-transition: color .4s;
}

.switcherContainer input {
  display:none;
}

.switch:before {
  position: absolute;
  display: flex;
  content: "";
  height: calc(100% - 4px);
  width: calc(50% - 2px);
  top: 2px;
  left: 2px;
  background-color: #99CC33;
  -webkit-transition: transform .4s;
  transition: transform .4s;
  border-radius: 6px;
}

input:checked + .switch:before {
  -webkit-transform: translateX(calc(100%));
  -ms-transform: translateX(calc(100%));
  transform: translateX(calc(100%));
}

input[type=checkbox]:not(:checked) + div > .switchTextLeft {
  color: white;
}

input[type=checkbox]:checked + div > .switchTextRight {
  color: white;
}
