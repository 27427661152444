#header {
  position: absolute;
  display: none;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 36px; /* DO NOT CHANGE THIS OR ADD ANY MARGIN OR PADDING! */
  z-index: 5;
  border-bottom: 1px solid #f2f2f2;
}

#header_user_container {
  display: none;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 0 15px 0 15px;
  position: relative;
}

#header_user, #header_auth {
  display: none;
  border: none;
  background-color: transparent;

  font-size: 13px;
  padding: 0;

  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
        user-select: none;
}
#header_user {
  display: flex;
   margin-right: 5px;
}
#header_auth {
  margin-left: 15px;
}

.header_auth_arrow {
  height: 8px;
  width: 8px;
  border-style: solid;
  border-color: #202020;
  border-width: 0px 2px 2px 0px;
  transform: rotate(45deg);
  margin-bottom: 5px;
}
