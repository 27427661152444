#lp {
  height: 100%;
  overflow: hidden;
  position: relative;
}

#lp_content {
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: #E9E9E9;
  height: 100%;
}

.lp_content_column {
  display: flex;
  flex-direction: column;
  padding: 155px 0 0 200px;
  flex: 1;
}

.lp_content_label {
  font-size: 76px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 70px;
  animation: text_color_change 20s cubic-bezier(0,0.5,0.5,1) infinite;
  user-select: none;
}

@keyframes text_color_change {
  0% { color: black; }
  25% { color: #99CC33; }
  50% { color: #ffca46; }
  75% { color: #99CC33; }
  100% { color: black; }
}

.lp_content_logo {
  display: flex;
  margin-bottom: 20px;
  /* min-height: 40px;
  max-height: 40px; */
  min-width: 265px;
  max-width: 265px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.lp_content_image_container {
  display: flex;
  flex: 2;
  margin: 50px;
  overflow: hidden;
}

.lp_content_image {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: auto;
}

.lp_content_buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 500px;
  margin: auto 0px 10vh 0px;
}

.lp_content_buttons_show {
  z-index: 15;
}

.lp_content_cta {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background-color: #99CC33;
  border: none;
  border-radius: 6px;
  height: 80px;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.lp_content_cta:hover {
  background-color: #6b9713
}

.lp_drawer_wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  display: flex;
  transition: background-color 0.4s ease;
}

.lp_drawer_wrapper_close {
  animation: drawer_close 0.4s step-end forwards;
}

.lp_drawer_wrapper_show {
  z-index: 11;
  background-color: #bfbfbf8c;

}

.lp_drawer_init, .lp_drawer_close, .lp_drawer_show {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  opacity: 0.97;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
  user-select: none;
  z-index: 11;
}

@keyframes drawer_close {
  from {
    z-index: 11;
  }
  to {
    z-index: 0;
  }
}

.lp_drawer_show {
  width: 700px;

  transition: width 0.4s ease-in-out;
}

.lp_drawer_init {
  width: 0;
}

.lp_drawer_close {
  width: 0;
  opacity: 1;

  transition: width 0.4s ease-in-out;
}

#lp_drawer_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.lp_drawer_flag {
  width: 70px;
  max-width: 70px;
  max-height: 70px;
  margin-left: 50px;
}

#lp_drawer_close_button {
  display: inline-block;
  justify-content: center;
  height: 25px;
  width: 25px;
  padding: 2px;
  border: none;
  outline: none;
  border-radius: 6px;
  background-color: white;
  margin-right: 30px;
  -webkit-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  -moz-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  cursor: pointer;
}

#lp_drawer_close_button > img {
  width: 21px;
  height: 21px;
}

#lp_drawer_form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: 100px;
}

/* BUG WATCH: display flex was added */
#lp_drawer_form {
  display: flex;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-width: 350px;
  max-width: 350px;
}
#lp_drawer_form > * {
  min-width: 250px;
}

.lp_drawer_form_label {
  display: inline-block;
  font-size: 42px;
  font-weight: 700;
  color: #99CC33;
  margin-bottom: 20px;
}

.submit_button {
  border: 1px solid #99CC33;
  border-radius: 6px;
  background-color: #99CC33;
  color: #fff;
  min-height: 50px;
  width: 100%;
  margin: 10px 0 15px 0;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.submit_button:hover {
  background-color: #6b9713
}

#lp_drawer_switch_forms {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#lp_drawer_switch_forms_button {
  margin-left: 5px;
  padding: 0;
  border: none;
  background: transparent;
  color: #99CC33;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  white-space: nowrap;
}

#forgotPass {
  color: #99CC33;
  user-select: none;
  cursor: pointer;
}

.resetPassContainer {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  z-index: 99;
  background-color: #ffffff88;
}

.resetPassForm {
  max-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-self: center;
  margin: 0 auto;
  padding: 50px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 0px 10px -1px #9a9a9a;
  -webkit-box-shadow: 0px 0px 10px -1px #9a9a9a;
  -moz-box-shadow: 0px 0px 10px -1px #9a9a9a;
}

.resetPassInput {
  width: 350px;
  max-width: 100%;
  min-height: 50px;
  padding: 0;
  padding-left: 15px;
  border: 2px solid #E6E5E5;
  border-radius: 6px;
  outline: none;
  margin-bottom: 15px;
}

.resetPassSubmit{
  border: 1px solid #99CC33;
  border-radius: 6px;
  background-color: #99CC33;
  color: #fff;
  min-height: 50px;
  width: 100%;
  margin: 10px 0 15px 0;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.resetPassForm > label {
  margin-bottom: 5px;
  user-select: none;
}

#reset_pass_close_button {
  display: flex;
  justify-content: center;
  display: flex;
  position: absolute;
  right: 15px;
  top: 15px;
  height: 25px;
  width: 25px;
  padding: 2px;
  border: none;
  outline: none;
  border-radius: 6px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  -moz-box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  box-shadow: 0px 2px 5px -1px rgba(199,199,199,1);
  cursor: pointer;
}

#reset_pass_close_button > img {
  width: 21px;
  height: 21px;
}

#lp_drawer_switch_forms_text {
  margin: 0;
  white-space: nowrap;
}

#lp_drawer_error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 350px;
  max-width: 350px;
  margin: 25px 0;
  padding: 20px 0px;
  border: 2px solid #99CC33;
  border-radius: 6px;
  position: relative;
  line-height: 1.15;
}

.lp_drawer_error_star {
  color: #99CC33;
  font-weight: 700;
  margin-right: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.auth_buttons_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    margin-top: 10px;
}

.lp_content_auth_button {
  flex: 1;
  font-size: 18px;
  font-weight: 600;
  color: #202020;
  background-color: #fff;
  border: none;
  border-radius: 6px;
  height: 80px;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.lp_content_auth_button:hover {
  background-color: hsl(0, 0%, 97%)
}
.lp_content_auth_button:first-child {
  margin-right: 10px;
}

.display_none {
  display: none;
}

@media only screen and (max-width: 1500px) {
  .lp_drawer_show {
    width: 500px;
  }
}

@media only screen and (max-width: 1250px) {
  .lp_drawer_show {
    opacity: 0.9;
  }

  #lp_content {
    flex-direction: column;
    height: 100%;
  }

  .lp_content_column {
    padding: 100px 600px 0 100px;
    z-index: 2;
  }

  .lp_content_label {
    margin-bottom: 45px;
  }

  .lp_content_image_container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    opacity: 0.65;
    z-index: 1;
    position: absolute;
    top: 0;
  }

  .lp_content_image {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .lp_content_label {
    font-size: 60px;
  }

  .lp_content_logo {
    min-width: 225px;
    max-width: 225px;
  }
}

@media only screen and (max-width: 900px) {
  .lp_drawer_show {
    width: 400px;
  }

  #lp_drawer_form {
    min-width: 0;
    max-width: none;
    width: calc(100% - 150px);
  }

  #lp_drawer_error {
    width: calc(100% - 150px);
  }

  .lp_content_column {
    padding: 100px 500px 0 50px;
  }

}

@media only screen and (max-height: 768px) {
  .lp_content_label {
    font-size: 36px;
  }
  .lp_content_cta, .lp_content_auth_button {
    height: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .lp_content_column {
    position: relative;
    padding: 75px 150px 0 50px;
  }

  .lp_content_logo {
    margin-bottom: 45px;
    min-width: 325px;
    max-width: 325px;
    /* min-height: 50px;
    max-height: 50px; */
  }

  #lp_drawer_form_container {
    margin-top: 60px;
    padding-bottom: 60px;
  }

  .lp_content_cta {
    border-radius: 6px;
    height: 60px;
    max-width: none;
    width: calc(100% - 100px);
    margin: auto;

    position: absolute;
    bottom: 130px;
    left: 0;
    right: 0;
  }

  .auth_buttons_container {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    margin: auto;
    width: calc(100% - 100px);
  }

  .lp_content_auth_button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: calc(50% - 5px);
  }

  .lp_content_image_container {
    height: 100%;
    width: 100%;
    margin: 0;
  }

  .lp_content_image {
    padding-left: 50px;
  }

  .lp_drawer_show {
    width: 100%;
    max-width: 100%;
    opacity: 1;
  }

  .lp_drawer_form_label {
    font-size: 36px;
  }

  .lp_drawer_flag {
    max-height: 50px;
    margin-left: 30px;
  }
}

@media only screen and (max-width: 420px) {
  #lp_drawer_form {
    width: calc(100% - 50px);
  }

  #lp_drawer_error {
    width: calc(100% - 50px);
  }

  .lp_content_column {
    padding: 50px 50px 0 50px;
  }

  .lp_content_logo {
    min-width: 255px;
    max-width: 255px;
  }

  .lp_content_label {
    font-size: 40px;
  }
}

@media only screen and (max-width: 320px) {
  #lp_drawer_form {
    width: calc(100% - 50px);
  }

  #lp_drawer_error {
    width: calc(100% - 50px);
  }

  .lp_content_column {
    padding: 25px 25px 0 25px
  }

  .lp_content_logo {
    min-width: 200px;
    max-width: 200px;
  }

  #lp_drawer_error {
    margin: 20px 0;
  }

  .lp_content_cta {
    bottom: 80px;
    font-size: 16px;
    height: 50px;
    width: calc(100% - 50px);

  }

  .auth_buttons_container {
    width: calc(100% - 50px);
    bottom: 20px;
  }

  .lp_content_auth_button {
    font-size: 16px;
    height: 50px;
  }

  .lp_content_label {
    font-size: 32px;
  }

  .submit_button {
    min-height: 40px;
  }

  .lp_drawer_flag {
    max-height: 40px;
  }

  .lp_drawer_form_label {
    font-size: 26px;
    margin-bottom: 10px;
  }

  #lp_drawer_close_button {
    min-height: 26px;
    min-width: 26px;
  }

  #reset_pass_close_button {
    min-height: 26px;
    min-width: 26px;
  }
}
