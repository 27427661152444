/* Isometric controls switch */

div#isometricControl {
  position: absolute;
  right: 0;
  margin-right: 80px;
  /* margin-top: 20px; */
  z-index: 5;
}

div#isometricControl div.qs_main div.qs_content {
  background-color: transparent;
  width: 100%;
}

div#isometricControl div.qs_main div.qs_content div.qs_container {
  background-color: transparent;
}

div#isometricControl div.qs_main {
  background-color: transparent;
  box-shadow: none;
  width: auto;
  top: 4px !important;
}

div#isometricControl div.qs_main div.qs_title_bar {
  display: none;
  background-color: transparent;
}

div#isometricControl div.qs_main div.qs_content div.qs_container label.qs_boolean_switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

div#isometricControl div.qs_main div.qs_content div.qs_container label.qs_boolean_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

div#isometricControl div.qs_main div.qs_content div.qs_container label.qs_boolean_switch span.qs_boolean_switch_checkbox_span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e9e9e9;
  -webkit-transition: transform .4s;
  transition: transform .4s;
}

div#isometricControl div.qs_main div.qs_content div.qs_container label.qs_boolean_switch span.qs_boolean_switch_checkbox_span:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 28px;
  left: 2px;
  bottom: 2px;
  background-color: #99CC33;
  -webkit-transition: transform .4s;
  transition: transform .4s;
}

/* div#isometricControl div.qs_main div.qs_content div.qs_container label.qs_boolean_switch input:focus +
.qs_boolean_switch_checkbox_span {
  box-shadow: 0 0 1px #666;
} */

div#isometricControl div.qs_main div.qs_content div.qs_container label.qs_boolean_switch input:checked +
.qs_boolean_switch_checkbox_span:before {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

/* Rounded sliders */
div#isometricControl div.qs_main div.qs_content div.qs_container label.qs_boolean_switch span.qs_boolean_switch_checkbox_span {
  border-radius: 6px;
}

div#isometricControl div.qs_main div.qs_content div.qs_container label.qs_boolean_switch span.qs_boolean_switch_checkbox_span:before {
  border-radius: 6px;
}

.qs_boolean_switch_checkbox_text_wrapper {
  height: 100%;
  flex-direction: row;
  display: flex;
}

.qs_boolean_switch_checkbox_text_not_checked,
.qs_boolean_switch_checkbox_text_checked {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

div#isometricControl div.qs_main div.qs_content div.qs_container label.qs_boolean_switch span.qs_boolean_switch_checkbox_span div.qs_boolean_switch_checkbox_text_wrapper div.qs_boolean_switch_checkbox_text_not_checked {
  padding-left: 2px;
}
div#isometricControl div.qs_main div.qs_content div.qs_container label.qs_boolean_switch span.qs_boolean_switch_checkbox_span div.qs_boolean_switch_checkbox_text_wrapper div.qs_boolean_switch_checkbox_text_checked {
  padding-right: 2px;
  color: #666;
}

div#isometricControl div.qs_main div.qs_content div.qs_container label.qs_boolean_switch span.qs_boolean_switch_checkbox_span div.qs_boolean_switch_checkbox_text_wrapper div.qs_boolean_switch_checkbox_text_not_checked {
	z-index: 5;
	color: white;
  transition: color .4s;
}

div#isometricControl div.qs_main div.qs_content div.qs_container label.qs_boolean_switch input:checked +
.qs_boolean_switch_checkbox_span > .qs_boolean_switch_checkbox_text_wrapper > .qs_boolean_switch_checkbox_text_checked {
	z-index: 5;
  color: white !important;
  transition: color .4s;
}

div#isometricControl div.qs_main div.qs_content div.qs_container label.qs_boolean_switch input:checked +
.qs_boolean_switch_checkbox_span > .qs_boolean_switch_checkbox_text_wrapper > .qs_boolean_switch_checkbox_text_not_checked {
  color: #666 !important;
}
