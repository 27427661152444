.lp_drawer_select {
  flex: 1;
  display: flex;
  min-width: 80px;
  min-height: 40px;
  max-height: 40px;
  background-color: #fff;
  border: 2px solid #E6E5E5;
  border-radius: 6px;
  padding: 1px 0px 1px 10px;
  outline: none;
  box-sizing: unset;
  -webkit-appearance: none;
}

.lp_drawer_select_container {
  position: relative;
  display: flex;
}

.lp_drawer_select_arrow {
  max-width: 26px;
  min-width: 26px;
  max-height: 26px;
  min-height: 26px;
  position: absolute;
  opacity: 0.4;
  top: 22%;
  right: 15px;
  pointer-events: none;
}

@media only screen and (max-width: 320px) {
  .lp_drawer_select {
    min-height: 30px;
  }

  .lp_drawer_select_arrow {
    top: 18%;
  }
}
